import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { mergeDeep } from '../../helpers/mergeDeep';
import windowExists from '../../helpers/window-exists';
import { useKeyDown } from '../../hooks';
import { useTheme } from '../Flowbite/ThemeContext';
import { ModalBody } from './ModalBody';
import { ModalContext } from './ModalContext';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
const ModalComponent = ({ children, className, dismissible = false, onClose, popup, position = 'center', root, show, size = '2xl', theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.modal, customTheme);
    const [mounted, setMounted] = useState(false);
    // Declare a ref to store a reference to a div element.
    const containerRef = useRef(null);
    useEffect(() => {
        setMounted(true);
        return () => {
            const container = containerRef.current;
            // If a container exists on unmount, it is removed from the DOM and
            // garbage collected.
            if (container) {
                container.parentNode?.removeChild(container);
                containerRef.current = null;
            }
        };
    }, []);
    useKeyDown('Escape', () => {
        if (dismissible && onClose) {
            onClose();
        }
    });
    if (!mounted) {
        return null;
    }
    // If the current value of the ref is falsy (e.g. null), set it to a new div
    // element.
    if (!containerRef.current) {
        containerRef.current = document.createElement('div');
    }
    // If the current value of the ref is not already a child of the root element,
    // append it or replace its parent.
    if (containerRef.current.parentNode !== root && windowExists()) {
        root ||= document.body;
        root.appendChild(containerRef.current);
        // Prevent scrolling of the root element when the modal is shown
        root.style.overflow = show ? 'hidden' : 'auto';
    }
    const handleOnClick = (e) => {
        if (dismissible && e.target === e.currentTarget && onClose) {
            onClose();
        }
    };
    return createPortal(_jsx(ModalContext.Provider, { value: { popup, onClose }, children: _jsx("div", { "aria-hidden": !show, "data-testid": "modal", onClick: handleOnClick, role: "dialog", className: classNames(theme.root.base, theme.root.positions[position], show ? theme.root.show.on : theme.root.show.off, className), ...props, children: _jsx("div", { className: classNames(theme.content.base, theme.root.sizes[size]), children: _jsx("div", { className: theme.content.inner, children: children }) }) }) }), containerRef.current);
};
ModalComponent.displayName = 'Modal';
ModalHeader.displayName = 'Modal.Header';
ModalBody.displayName = 'Modal.Body';
ModalFooter.displayName = 'Modal.Footer';
export const Modal = Object.assign(ModalComponent, { Header: ModalHeader, Body: ModalBody, Footer: ModalFooter });
