import React from 'react';

import { Card, Grid } from '../../components/Flowbite';
import { BalanceSheet } from '../../components/Data';

function Finance() {
  return (
    <>
      <Grid cols={6} gap={4}>
        <Card className="col-span-6">
          <BalanceSheet />
        </Card>
      </Grid>
    </>
  );
}

export default Finance;
