import React from 'react';
import { reverse, sortBy } from 'lodash';
import { DateTime } from 'luxon';

import { useAuthentication } from '../../contexts/Authentication';
import Title from './Title';
import { SpreadsheetProxy, Values } from './types';
import { excelDate } from './utils';

const range = 'Sales - Closed Won!A2:C1000';

function Pipeline() {
  const { api } = useAuthentication();
  const [values, setValues] = React.useState<Values>([]);

  const load = async () => {
    const { data } = await api.get<SpreadsheetProxy>('/proxy/google-sheets', { params: { range } });
    setValues(data.data.values);
  };

  React.useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <Title subtitle="Pipeline by month">Pipeline</Title>
    </div>
  );
}

export default Pipeline;
