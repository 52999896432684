import React from 'react';
import { camelCase, slice } from 'lodash';

import { useAuthentication } from '../../contexts/Authentication';
import { SpreadsheetProxy, Values } from './types';
import Title from './Title';
import { excelDate } from './utils';

const range = 'Financial - Revenue!A2:AK8';

interface RevenueRowProps {
  label: string;
  value: number[];
  strong?: boolean;
  suffix?: string;
  prefix?: string;
}

function limitData<T>(data: (string | number)[], limit: number) {
  const availableData =  slice(data, 0, limit) as T[];
  return slice(availableData, availableData.length - 12);
}

function RevenueRow({ label, value, strong = false, suffix = '', prefix = '' }: RevenueRowProps) {
  const id = React.useMemo(() => crypto.randomUUID(), []);

  const className = strong ? 'bg-white border-b dark:bg-gray-800 dark:border-gray-70 font-semibold' : 'bg-white border-b dark:bg-gray-800 dark:border-gray-70';

  return (
    <tr className={className}>
      <td scope="row" className="px-6 py-4">{label}</td>
      {
         value.map((v, idx) => (<td key={`${id}:${idx}`} className="px-6 py-4 text-right">{prefix}{Math.round(v)}{suffix}</td>))
      }
    </tr>
  );
}

function RevenueTable() {
  const { api } = useAuthentication();
  const [values, setValues] = React.useState<Values>([]);

  const load = async () => {
    const { data } = await api.get<SpreadsheetProxy>('/proxy/google-sheets', { params: { range } });
    setValues(data.data.values);
  };

  const tableData = React.useMemo(() => {
    if (values.length === 0) return {};

    // Build data
    const data: Record<string, (number | string)[]> = {};
    values.forEach((row) => {
      let key: string | number = '';
      row.forEach((col, colIdx) => {
        // Set the key
        if (colIdx === 0) {
          key = col === '' ? 'date' : camelCase(String(col));
          data[key] = [];
        } else {
          data[key].push(col);
        }
      });
    });


    return data;
  }, [values]);

  React.useEffect(() => {
    load();
  }, []);

  const limit = (tableData.netRevenueFromResell || []).findIndex((v) => (v === 0));

  const percentages = React.useMemo(() => {
    if (!tableData.netRevenueFromResell) return [];
    const productResellRevenue = limitData<number>(tableData.productResellRevenue, limit);
    const netRevenueFromResell = limitData<number>(tableData.netRevenueFromResell, limit)
    return productResellRevenue.map((r, idx) => (Math.round((netRevenueFromResell[idx] / r) * 100)));
  }, [tableData]);

  return (
    <div>
      <Title subtitle="Revenue breakdown detail for the last year by month">Revenue details</Title>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th />
            {
              limitData<number>(tableData.date, limit).map((date) => (
                <th key={date} scope="col" className="px-6 py-3 text-right">{excelDate(date).toFormat('LLL yy')}</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          <RevenueRow label="Product Resell Revenue" value={limitData<number>(tableData.productResellRevenue, limit)} />
          <RevenueRow label="Product Resell Direct Expenses" value={limitData<number>(tableData.productResellDirectExpenses, limit)} />
          <RevenueRow label="Product Resell Net Revenue" value={limitData<number>(tableData.netRevenueFromResell, limit)} strong />
          <RevenueRow label="Product Resell Net Revenue %" value={percentages} suffix="%" />
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"><td className="py-2" /></tr>
          <RevenueRow label="Professional Services Revenue" value={limitData<number>(tableData.professionalServicesRevenue, limit)} />
          <RevenueRow label="Other Revenue" value={limitData<number>(tableData.otherRevenue, limit)} />
          <RevenueRow label="Total Revenue" value={limitData<number>(tableData.totalRevenue, limit)} strong />
        </tbody>
      </table>
    </div>
  );
}

export default RevenueTable;
