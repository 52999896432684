import React from 'react';
import { useHref, useLinkClickHandler } from 'react-router-dom';

interface SidenavLogoProps {
  to: string;
  src: string;
  className: string;
}

function SidenavLogo({ to, src, className }: SidenavLogoProps) {
  return (
    <a href={useHref(to)} className="flex items-center pl-2.5 mb-5" onClick={useLinkClickHandler(to)}>
      <img src={src} alt="Enablo" className={className} />
    </a>
  );
}

export default SidenavLogo;
