import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
export const ListGroupItem = ({ active: isActive, children, className, href, icon: Icon, onClick, theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.listGroup.item, customTheme);
    const isLink = typeof href !== 'undefined';
    const Component = isLink ? 'a' : 'button';
    return (_jsx("li", { className: classNames(theme.base, className), children: _jsxs(Component, { href: href, onClick: onClick, type: isLink ? undefined : 'button', className: classNames(theme.link.active[isActive ? 'on' : 'off'], theme.link.base, theme.link.href[isLink ? 'on' : 'off']), ...props, children: [Icon && _jsx(Icon, { "aria-hidden": true, "data-testid": "flowbite-list-group-item-icon", className: theme.link.icon }), children] }) }));
};
