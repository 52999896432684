import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { Children, cloneElement, useMemo } from 'react';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
const ButtonGroup = ({ children, className, outline, pill, theme: customTheme = {}, ...props }) => {
    const items = useMemo(() => Children.map(children, (child, index) => cloneElement(child, {
        outline,
        pill,
        positionInGroup: index === 0 ? 'start' : index === children.length - 1 ? 'end' : 'middle',
    })), [children, outline, pill]);
    const theme = mergeDeep(useTheme().theme.buttonGroup, customTheme);
    return (_jsx("div", { className: classNames(theme.base, className), role: "group", ...props, children: items }));
};
ButtonGroup.displayName = 'Button.Group';
export default ButtonGroup;
