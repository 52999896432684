import { DateTime } from 'luxon';
import { default as ax } from 'axios';

import LineFitter from './LineFitter';

export const excelDate = (serial: number) => {
  const hours = Math.floor((serial % 1) * 24);
  const minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
  return DateTime.fromJSDate(new Date(Date.UTC(0, 0, serial, hours - 17, minutes)));
}

export const trendLine = (values: number[]) => {
  const trend = new LineFitter();
  values.forEach((value, idx) => trend.add(idx, value));
  const trendData = values.map((value, idx) => trend.project(idx));
  return trendData;
};

export const monthRange = (values: DateTime[]) => {
  let min = DateTime.now();
  let max = DateTime.now();
  const range = [];

  values.forEach((value) => {
    if (value < min) min = value;
    if (value > max) max = value;
  });

  const months = Math.round(max.diff(min, 'months').toObject().months || 0);

  for (let i = 0; i < months; i += 1) {
    const thisMonth = min.plus({ month: i });
    range.push(thisMonth);
  }

  return range;
};

