import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
const PaginationButton = ({ active, children, className, onClick, theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.pagination, customTheme);
    return (_jsx("button", { className: classNames({
            [theme.pages.selector.active]: active,
        }, className), onClick: onClick, ...props, children: children }));
};
PaginationButton.displayName = 'Pagination.Button';
export default PaginationButton;
