import React from 'react';
import Chart from 'react-apexcharts';

import Title from './Title';
import { SpreadsheetProxy, Values } from './types';
import { cloneDeep } from 'lodash';
import { useAuthentication } from '../../contexts/Authentication';


const range = 'HR - Misc!A1:Z4';

const charts = {
  options: {
    chart: { id: 'tenure', stacked: true },
    xaxis: {
      categories: [] as string[],
    },
  },
  series: [] as { name: string, data: number[] }[],
};

function Tenure() {
  const { api } = useAuthentication();
  const [values, setValues] = React.useState<Values>([]);

  const load = async () => {
    const { data } = await api.get<SpreadsheetProxy>('/proxy/google-sheets', { params: { range } });
    setValues(data.data.values);
  };

  const chart = React.useMemo(() => {
    if (values.length === 0) return charts;

    const c = cloneDeep(charts);

    c.options.xaxis.categories = values[0].filter((v) => v !== 'Tenure') as string[];

    values.forEach((row, idx) => {
      if (idx === 0) return;
      const [label, ...columns] = row;
      c.series.push({
        name: label as string,
        data: columns as number[],
      })
    });

    return c;
  }, [values]);

  React.useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <Title subtitle="Average employment tenure">Tenure</Title>
      <div className="px-4 pb-4">
        <Chart
          options={chart.options}
          series={chart.series}
          type="bar"
          height={300}
        />
      </div>
    </div>
  );
}

export default Tenure;
