import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { HiOutlineX } from 'react-icons/hi';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
import { useModalContext } from './ModalContext';
export const ModalHeader = ({ as: Component = 'h3', children, className, theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.modal.header, customTheme);
    const { popup, onClose } = useModalContext();
    return (_jsxs("div", { className: classNames(theme.base, {
            [theme.popup]: popup,
        }, className), ...props, children: [_jsx(Component, { className: theme.title, children: children }), _jsx("button", { "aria-label": "Close", className: theme.close.base, type: "button", onClick: onClose, children: _jsx(HiOutlineX, { "aria-hidden": true, className: theme.close.icon }) })] }));
};
