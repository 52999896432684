import { jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite';
const AvatarGroupCounter = ({ className, href, theme: customTheme = {}, total, ...props }) => {
    const theme = mergeDeep(useTheme().theme.avatar.groupCounter, customTheme);
    return (_jsxs("a", { href: href, className: classNames(theme.base, className), ...props, children: ["+", total] }));
};
AvatarGroupCounter.displayName = 'Avatar.GroupCounter';
export default AvatarGroupCounter;
