import React from 'react';

interface TitleProps {
  children: React.ReactNode;
  subtitle?: string;
}

function Title({ children, subtitle }: TitleProps) {
  return (
    <div className="p-4">
      <h6 className="text-lg font-bold dark:text-white">{children}</h6>
      {subtitle && <p className="text-sm text-gray-500 dark:text-white">{subtitle}</p>}
    </div>
  );
}

export default Title;
