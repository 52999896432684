import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
import { RatingAdvanced } from './RatingAdvanced';
import { RatingContext } from './RatingContext';
import { RatingStar } from './RatingStar';
const RatingComponent = ({ children, className, size = 'sm', theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.rating, customTheme);
    return (_jsx(RatingContext.Provider, { value: { size }, children: _jsx("div", { className: classNames(theme.root.base, className), ...props, children: children }) }));
};
RatingComponent.displayName = 'Rating';
RatingStar.displayName = 'Rating.Star';
RatingAdvanced.displayName = 'Rating.Advanced';
export const Rating = Object.assign(RatingComponent, {
    Star: RatingStar,
    Advanced: RatingAdvanced,
});
