import React from 'react';

interface FormatCellProps {
  value: number | string;
}

const format = (value: number | string) => {
  if (typeof value === 'number' && value < 0) return `(${Math.abs(value).toLocaleString('en-US')})`;
  if (typeof value === 'number') return value.toLocaleString('en-US');
  return value;
}

function FormatCell({ value }: FormatCellProps) {
  return (
    <>{format(value)}</>
  );
}

export default FormatCell;
