import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import windowExists from '../../helpers/window-exists';
import defaultTheme from '../../theme/default';
export const ThemeContext = createContext({
    theme: defaultTheme,
});
export const ThemeProvider = ({ children, value }) => {
    return _jsx(ThemeContext.Provider, { value: value, children: children });
};
export function useTheme() {
    return useContext(ThemeContext);
}
export const useThemeMode = () => {
    const userPreferenceIsDark = () => windowExists() && window.matchMedia?.('(prefers-color-scheme: dark)').matches;
    const getPrefersColorScheme = () => (userPreferenceIsDark() ? 'dark' : 'light');
    const onToggleMode = () => {
        const newMode = mode === 'dark' ? 'light' : 'dark';
        setMode(newMode);
        setModeState(newMode);
    };
    const { mode: contextMode, toggleMode = onToggleMode } = useContext(ThemeContext);
    const [mode, setModeState] = useState(contextMode ? contextMode : getPrefersColorScheme());
    const setMode = useCallback((mode) => {
        if (!windowExists()) {
            return;
        }
        if (mode === 'dark') {
            document.documentElement.classList.add('dark');
            return;
        }
        document.documentElement.classList.remove('dark');
    }, []);
    useEffect(() => {
        if (contextMode) {
            setMode(contextMode);
            setModeState(contextMode);
        }
    }, [contextMode, setMode, setModeState]);
    return [mode, setModeState, toggleMode];
};
