import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
export const Radio = forwardRef(({ className, theme: customTheme = {}, ...props }, ref) => {
    const theme = mergeDeep(useTheme().theme.radio, customTheme);
    return _jsx("input", { ref: ref, type: "radio", className: classNames(theme.root.base, className), ...props });
});
Radio.displayName = 'Radio';
