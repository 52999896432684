import React from 'react';
import {
  IconAnalyze, IconReportMoney, IconChartAreaLineFilled, IconChecklist, IconSpeakerphone,
  IconChalkboard, IconFriends, IconCode,
} from '@tabler/icons-react';

import { Sidenav, SidenavLogo, SidenavMenu, SidenavItem, SidenavGroup, SidenavGroupItem } from '../Flowbite/Sidenav';

import logo from './enablo.png';
import { enabloLogo } from './layout.module.scss';

function Component() {
  return (
    <Sidenav>
      <SidenavLogo to="/" src={logo} className={enabloLogo} />
      <SidenavMenu>
        <SidenavItem to="/executive-summary" icon={IconAnalyze}>Executive Summary</SidenavItem>
        <SidenavGroup label="Finance" icon={IconReportMoney}>
          <SidenavGroupItem to="/finance-revenue">Revenue</SidenavGroupItem>
          <SidenavGroupItem to="/finance-profit-loss">Profit & Loss</SidenavGroupItem>
          <SidenavGroupItem to="/finance-balance-sheet">Balance Sheet</SidenavGroupItem>
        </SidenavGroup>
        <SidenavItem to="/operations-hr" icon={IconChecklist}>Operations &amp; HR</SidenavItem>
        <SidenavItem to="/sales" icon={IconChartAreaLineFilled}>Sales</SidenavItem>
        <SidenavItem to="/marketing" icon={IconSpeakerphone}>Marketing</SidenavItem>
        <SidenavItem to="/professional-services" icon={IconChalkboard}>Professional Services</SidenavItem>
        <SidenavItem to="/customer-success" icon={IconFriends}>Customer Success</SidenavItem>
        <SidenavItem to="/engineering" icon={IconCode}>Engineering</SidenavItem>
      </SidenavMenu>
    </Sidenav>
  );
}

export default Component;
