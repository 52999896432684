class LineFitter {
  count: number;

  sumX: number;

  sumX2: number;

  sumXY: number;

  sumY: number;

  constructor() {
    this.count = 0;
    this.sumX = 0;
    this.sumX2 = 0;
    this.sumXY = 0;
    this.sumY = 0;
  }

  add (x: number, y: number) {
    this.count++;
    this.sumX += x;
    this.sumX2 += x*x;
    this.sumXY += x*y;
    this.sumY += y;
  }

  project (x: number) {
    const det = this.count * this.sumX2 - this.sumX * this.sumX;
    const offset = (this.sumX2 * this.sumY - this.sumX * this.sumXY) / det;
    const scale = (this.count * this.sumXY - this.sumX * this.sumY) / det;
    return offset + x * scale;
  }
}

export default LineFitter;
