import React from 'react';
import Chart from 'react-apexcharts';

import Title from './Title';
import { Grid } from '../Flowbite'

// Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec

const charts = {
  options: {
    chart: { id: 'bookings' },
    xaxis: {
      categories: ['Apr 22', 'May 22', 'Jun 22', 'Jul 22', 'Aug 22', 'Sep 22', 'Oct 22', 'Nov 22', 'Dec 22', 'Jan 23', 'Feb 23', 'Mar 23'],
    },
  },
  bookings: [{
    name: 'Bookings',
    data: [10000, 11000, 12000, 11000, 9000, 6000, 2000, 7000, 8000, 15000, 12000, 3000]
  }],
  professionServices: [{
    name: 'Professional Services',
    data: [5000, 2000, 1000, 6000, 8000, 10000, 12000, 8000, 9000, 6000, 11000, 6000]
  }],
  income: [{
    name: 'Income',
    data: []
  }],
  profit: [{
    name: 'Profit',
    data: []
  }]
};

interface ButtonProps {
  label: string;
  onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void,
}

function Button({ label, onClick }: ButtonProps) {
  return (
    <button
      type="button"
      className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      onClick={onClick}
    >
      {label}
    </button>
  );
}

function EarningsReport() {
  const [active, setActive] = React.useState(0);

  const series = React.useMemo(() => {
    if (active === 1) return charts.professionServices;
    return charts.bookings;
  }, [active]);

  const handleClick = (tab: number) => () => {
    setActive(tab);
  };

  return (
    <div>
      <Title subtitle="Yearly Earnings Overview">Earnings Report</Title>
      <div className="px-4 pb-4">
        <Grid cols={6} gap={2}>
          <Button label="Bookings" onClick={handleClick(0)} />
          <Button label="Professional Services" onClick={handleClick(1)} />
          <Button label="Income" onClick={handleClick(2)} />
          <Button label="Profit" onClick={handleClick(3)} />
        </Grid>
        <Chart
          options={charts.options}
          series={series}
          type="bar"
          height={300}
        />
      </div>
    </div>
  );
}

export default EarningsReport;
