import React from 'react';
import { useAuth0, User, GetTokenSilentlyOptions } from '@auth0/auth0-react';
import axios, { Axios } from 'axios';

const baseURL = window.location.hostname === 'enablo-dashboard.jaxma.au'
  ? 'https://us-central1-jaxma-386121.cloudfunctions.net/enablo-dashboad'
  : 'http://127.0.0.1:3000';

interface AuthenticationContextProps {
  isAuthenticated: boolean;
  loading: boolean;
  user?: User;
  getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>;
  api: Axios;
}

const AuthenticationContext = React.createContext<AuthenticationContextProps>({
  isAuthenticated: false,
  loading: true,
  user: undefined,
  getAccessTokenSilently: async ({}) => '',
  api: axios.create(),
});

export const useAuthentication = () => {
  const context = React.useContext(AuthenticationContext);
  if (!context) throw new Error('useAuthentication must be used inside the Authentication Provider');
  return context;
};

export function AuthenticationProvider({ children }: React.PropsWithChildren) {
  const { user, isAuthenticated, getAccessTokenSilently, isLoading, loginWithRedirect } = useAuth0();
  const [loading, setLoading] = React.useState(true);

  const api = axios.create({
    baseURL,
  });

  api.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    config.headers.setAuthorization(`Bearer ${token}`);
    return config;
  });

  const value = React.useMemo(() => ({
    api,
    loading,
    isAuthenticated,
    user,
    getAccessTokenSilently,
  }), [isAuthenticated, loading, user,]);

  const load = async () => {
    if (!isAuthenticated) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    load();
  }, []);

  if (loading || isLoading) {
    return (
      <div>Loading</div>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
}

