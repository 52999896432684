import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite';
import { useTimelineContext } from './TimelineContext';
export const TimelinePoint = ({ children, className, icon: Icon, theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.timeline.item.point, customTheme);
    const { horizontal } = useTimelineContext();
    return (_jsxs("div", { "data-testid": "timeline-point", className: classNames(horizontal && theme.horizontal, !horizontal && theme.vertical, className), ...props, children: [children, Icon ? (_jsx("span", { className: classNames(theme.marker.icon.wrapper), children: _jsx(Icon, { "aria-hidden": true, className: classNames(theme.marker.icon.base) }) })) : (_jsx("div", { className: classNames(horizontal && theme.marker.base.horizontal, !horizontal && theme.marker.base.vertical) })), horizontal && _jsx("div", { className: classNames(theme.line) })] }));
};
