import React from 'react';
import { Outlet } from 'react-router-dom';

import { Page } from './components/Layout';

function App() {
  return (
    <Page>
      <main role="main" className="w-full h-full flex-grow p-3 overflow-auto">
        <Outlet />
      </main>
    </Page>
  )
}

export default App;
