import React from 'react';
import Chart from 'react-apexcharts';
import { cloneDeep, camelCase, slice } from 'lodash';

import { useAuthentication } from '../../contexts/Authentication';
import Title from './Title';
import { SpreadsheetProxy, Values } from './types';
import { excelDate, trendLine } from './utils';

const range = 'Financial - Revenue!A2:AK8';

const charts = {
  options: {
    chart: { id: 'revenue', stacked: false },
    dataLabels: { enabled: false },
    xaxis: {
      categories: [] as string[],
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
      labels: {
        formatter: (value: number) => String(Math.round(value)),
      }
    },
    colors: ['#008FFB', '#00E396', '#FEB019'],
    stroke: {
      width: [0, 0, 0, 3, 3, 3],
      dashArray: [0, 0, 0, 8, 8, 8],
      curve: 'straight' as "straight",
    },
  },
  series: [] as { name: string, data: number[], type: string }[],
};

function limitData<T>(data: (string | number)[], limit: number) {
  const availableData = slice(data, 0, limit) as T[];
  return slice(availableData, availableData.length - 24);
}

function RevenueChart() {
  const { api } = useAuthentication();
  const [values, setValues] = React.useState<Values>([]);

  const load = async () => {
    const { data } = await api.get<SpreadsheetProxy>('/proxy/google-sheets', { params: { range } });
    setValues(data.data.values);
  };

  React.useEffect(() => {
    load();
  }, []);

  const chart = React.useMemo(() => {
    const c = cloneDeep(charts);

    if (values.length === 0) return c;

    // Build data
    const data: Record<string, (number | string)[]> = {};
    values.forEach((row) => {
      let key: string | number = '';
      row.forEach((col, colIdx) => {
        // Set the key
        if (colIdx === 0) {
          key = col === '' ? 'date' : camelCase(String(col));
          data[key] = [];
        } else {
          data[key].push(col);
        }
      });
    });

    const limit = data.netRevenueFromResell.findIndex((v) => (v === 0));

    // Map data to chart
    c.options.xaxis.categories = limitData<string>(data.date.map((d) => excelDate(d as number).toFormat('LLL yy')), limit);
    c.series.push({ name: 'Net Resell Revenue', data: limitData<number>(data.netRevenueFromResell, limit), type: 'bar' });
    c.series.push({ name: 'Professional Services Revenue', data: limitData<number>(data.professionalServicesRevenue, limit), type: 'bar' });
    c.series.push({ name: 'Other Revenue', data: limitData<number>(data.otherRevenue, limit), type: 'bar' });
    c.series.push({ name: 'Net Resell Revenue Trend', data: trendLine(limitData<number>(data.netRevenueFromResell, limit)), type: 'line'  });
    c.series.push({ name: 'Professional Services Revenue Trend', data: trendLine(limitData<number>(data.professionalServicesRevenue, limit)), type: 'line'  });
    c.series.push({ name: 'Other Revenue Trend', data: trendLine(limitData<number>(data.otherRevenue, limit)), type: 'line' });

    return c;
  }, [values]);

  return (
    <div>
      <Title subtitle="Revenue breakdown for the last two years by month">Revenue</Title>
      <div className="px-4 pb-4">
        <Chart
          options={chart.options}
          series={chart.series}
          height={500}
          type="bar"
        />
      </div>
    </div>
  );
}

export default RevenueChart;
