import React from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import classNames from 'classnames';


interface SidenavGroupProps {
  label: string;
  icon?: (props: { className: string }) => JSX.Element;
}

function SidenavGroup({ children, label, icon: Icon }: React.PropsWithChildren & SidenavGroupProps) {
  const [hidden, setHidden] = React.useState(true);

  const handleClick = () => {
    setHidden(!hidden);
  };

  return (
    <li>
      <button type="button" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" onClick={handleClick}>
        {Icon && (<Icon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />)}
        <span className="flex-1 ml-3 text-left whitespace-nowrap">{label}</span>
        <IconChevronDown className={classNames('transition ease-in-out w-6 h-6', { 'rotate-180': !hidden	})} />
      </button>
      <ul className={classNames({ hidden }, 'py-2 space-y-2')}>
        {children}
      </ul>
    </li>
  );
}

export default SidenavGroup;
