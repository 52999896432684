import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite';
import { useTableContext } from './TableContext';
export const TableRow = ({ children, className, theme: customTheme = {}, ...props }) => {
    const { hoverable, striped } = useTableContext();
    const theme = mergeDeep(useTheme().theme.table.row, customTheme);
    return (_jsx("tr", { "data-testid": "table-row-element", className: classNames(theme.base, striped && theme.striped, hoverable && theme.hovered, className), ...props, children: children }));
};
