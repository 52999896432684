import React from 'react';
import classNames from 'classnames';

interface CardProps {
  className?: string;
  children?: React.ReactNode;
}

function Card({ children = null, className = '' }: CardProps) {
  return (
    <div className={classNames(className, 'bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700')}>
      {children}
    </div>
  );
}

export default Card;
