import React from 'react';
import { reverse, sortBy } from 'lodash';
import { DateTime } from 'luxon';

import Title from './Title';
import { SpreadsheetProxy, Values } from './types';
import { excelDate } from './utils';
import { useAuthentication } from '../../contexts/Authentication';

const range = 'Sales - Closed Won!A2:C1000';

function ClosedWonTable() {
  const { api } = useAuthentication();
  const [values, setValues] = React.useState<Values>([]);

  const load = async () => {
    const { data } = await api.get<SpreadsheetProxy>('/proxy/google-sheets', { params: { range } });
    setValues(data.data.values);
  };

  React.useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <Title subtitle="All Closed/Won deals">Closed/Won</Title>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Deal</th>
              <th scope="col" className="px-6 py-3">Date</th>
              <th scope="col" className="px-6 py-3">Value</th>
            </tr>
        </thead>
        <tbody>
          {
            reverse(sortBy(values, (v) => v[1])).map((row) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={`${row[0]}${row[1]}`}>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {row[0]}
                </th>
                <td className="px-6 py-4">
                  {excelDate(row[1] as number).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                </td>
                <td className="px-6 py-4">
                  {new Intl.NumberFormat().format(row[2] as number)}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default ClosedWonTable;
