import React from 'react';

import { Card, Grid } from '../../components/Flowbite';
import { ClosedWon, ClosedWonTable, Pipeline } from '../../components/Data';

function Sales() {
  return (
    <>
      <div className="mb-4">
        <Grid cols={2} gap={4}>
          <Card><ClosedWon /></Card>
          <Card><Pipeline /></Card>
        </Grid>
      </div>
      <div>
        <Card>
          <ClosedWonTable />
        </Card>
      </div>
    </>
  );
}

export default Sales;
