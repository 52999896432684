import React from 'react';
import { DateTime } from 'luxon';
import Chart from 'react-apexcharts';
import { cloneDeep } from 'lodash';

import { useAuthentication } from '../../contexts/Authentication';
import Title from './Title';
import { SpreadsheetProxy, Values } from './types';
import { excelDate, trendLine } from './utils';

const range = 'HR - Employees!A1:C100';

const charts = {
  options: {
    chart: { id: 'employees' },
    xaxis: {
      categories: [] as string[],
    },
    yaxis: {
      labels: {
        formatter: (value: number) => String(Math.round(value)),
      }
    },
    stroke: {
      width: [5, 3],
      dashArray: [0, 8],
    }
  },
  total: [{
    name: 'Employees',
    data: [] as number[],
  }]
};

function EmployeeCountOverTime() {
  const { api } = useAuthentication();
  const [values, setValues] = React.useState<Values>([]);

  const load = async () => {
    const { data } = await api.get<SpreadsheetProxy>('/proxy/google-sheets', { params: { range } });
    setValues(data.data.values);
  };

  const chart = React.useMemo(() => {
    if (values.length === 0) return charts;

    const c = cloneDeep(charts);

    let min = DateTime.now();
    let max = DateTime.now();

    // Determine range
    values.forEach((row) => {
      const start = excelDate(row[1] as number);
      const end = excelDate(row[2] as number);
      if (start < min) min = start;
      if (end > max) max = end;
    });

    const months = Math.round(max.diff(min, 'months').toObject().months || 0);

    // Build months object
    for (let i = 0; i < months; i += 1) {
      const thisMonth = min.plus({ month: i });
      const monthString = thisMonth.toFormat('LLL yyyy');
      let employees = 0;

      values.forEach((row) => {
        const start = excelDate(row[1] as number);
        const end = excelDate(row[2] as number);
        if (thisMonth >= start && thisMonth <= end ) employees += 1
      });

      c.options.xaxis.categories.push(monthString);
      c.total[0].data.push(employees);
    }

    c.total.push({
      name: 'Trend',
      data: trendLine(c.total[0].data),
    });

    return c;
  }, [values]);

  React.useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <Title subtitle="Total number of employees over time">Employee Count</Title>
      <div className="px-4 pb-4">
        <Chart
          options={chart.options}
          series={chart.total}
          type="line"
          height={300}
        />
      </div>
    </div>
  );
}

export default EmployeeCountOverTime;
