import React from 'react';
import Chart from 'react-apexcharts';
import { cloneDeep } from 'lodash';

import Title from './Title';
import { SpreadsheetProxy, Values } from './types';
import { excelDate, monthRange } from './utils';
import { useAuthentication } from '../../contexts/Authentication';

const range = 'Sales - Closed Won!A2:C1000';

const charts = {
  options: {
    chart: { id: 'tenure', stacked: true },
    xaxis: {
      categories: [] as string[],
    },
  },
  series: [] as { name: string, data: number[] }[],
};

function ClosedWon() {
  const { api } = useAuthentication();
  const [values, setValues] = React.useState<Values>([]);

  const load = async () => {
    const { data } = await api.get<SpreadsheetProxy>('/proxy/google-sheets', { params: { range } });
    setValues(data.data.values);
  };

  const chart = React.useMemo(() => {
    if (values.length === 0) return charts;

    const c = cloneDeep(charts);
    const ranges = monthRange(values.map((v) => excelDate(v[1] as number)));

    console.log(values);

    return c;
  }, [values]);

  React.useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <Title subtitle="All Closed/Won by month">Closed/Won</Title>
      <div className="px-4 pb-4">
        <Chart
          options={chart.options}
          series={chart.series}
          type="line"
          height={300}
        />
      </div>
    </div>
  );
}

export default ClosedWon;
