import React from 'react';

import { Card } from '../../components/Flowbite';
import { RevenueChart, RevenueTable } from '../../components/Data';

function FinanceSummary() {
  return (
    <>
      <Card className="mb-4">
        <RevenueChart />
      </Card>
      <Card>
        <RevenueTable />
      </Card>
    </>
  );
}

export default FinanceSummary;
