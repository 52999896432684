import React from 'react';

import { useAuthentication } from '../../contexts/Authentication';
import { SpreadsheetProxy, Values } from './types';
import { Card, Grid } from '../../components/Flowbite';
import Title from './Title';

const range = 'HR - Misc!A9:F10';

function HRStats() {
  const { api } = useAuthentication();
  const [values, setValues] = React.useState<Values>([]);

  const load = async () => {
    const { data } = await api.get<SpreadsheetProxy>('/proxy/google-sheets', { params: { range } });
    setValues(data.data.values);
  };

  React.useEffect(() => {
    load();
  }, []);

  if (values.length === 0) return null;

  return (
    <Grid cols={5} gap={4}>
      <Card>
        <Title subtitle="Total number of Enablo Employees">Employees</Title>
        <div className="text-6xl text-gray-900 px-4 pb-4">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-blue-600 from-sky-400">{values[1][1]}</span>
        </div>
      </Card>
      <Card>
        <Title subtitle="US team current open positions">Vacancies (US)</Title>
        <div className="text-6xl text-gray-900 px-4 pb-4">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-blue-600 from-sky-400">{values[1][2]}</span>
        </div>
      </Card>
      <Card>
        <Title subtitle="AU team current open positions">Vacancies (AU)</Title>
        <div className="text-6xl text-gray-900 px-4 pb-4">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-blue-600 from-sky-400">{values[1][3]}</span>
        </div>
      </Card>
      <Card>
        <Title subtitle="Total number of incidents">OH&S Incidents</Title>
        <div className="text-6xl text-gray-900 px-4 pb-4">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-blue-600 from-sky-400">{values[1][4]}</span>
        </div>
      </Card>
      <Card>
        <Title subtitle="Total number of disputes">HR-related disputes</Title>
        <div className="text-6xl text-gray-900 px-4 pb-4">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-blue-600 from-sky-400">{values[1][5]}</span>
        </div>
      </Card>
    </Grid>
  );
}

export default HRStats;
