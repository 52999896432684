import React from 'react';
import { uniqueId } from 'lodash';

import { SpreadsheetProxy, Values } from './types';
import Title from './Title';
import FormatCell from './FormatCell';
import { useAuthentication } from '../../contexts/Authentication';

const range = 'Financial - Balance Sheet!A1:E29';

const normalRow = 'bg-white border-b dark:bg-gray-800 dark:border-gray-700';
const semiboldRow = 'bg-white border-b dark:bg-gray-800 dark:border-gray-700 font-semibold';

const semiboldRows = [
  'Total Current Assets', 'Total Non-Current Assets', 'Total Assets', 'Total Current Liabilities',
  'Total Non-Current Liabilities', 'Total Liabilities', 'Net Assets', 'Net Assets', 'Total Equity',
];

function BalanceSheet() {
  const { api } = useAuthentication();
  const [values, setValues] = React.useState<Values>([]);

  const load = async () => {
    const { data } = await api.get<SpreadsheetProxy>('/proxy/google-sheets', { params: { range } });
    setValues(data.data.values);
  };

  React.useEffect(() => {
    load();
  }, []);

  const [heading, ...rows] = values;

  return (
    <>
      <Title subtitle="As of September 30, 2022 (USD)">Balance Sheet</Title>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
              {
                (heading || []).map((cell) => (
                  <th scope="col" className="px-6 py-3" key={uniqueId()}>{cell}</th>
                ))
              }
          </tr>
        </thead>
        <tbody>
          {
            (rows || []).map((row) => {
              const semibold = semiboldRows.includes(String(row[0]).trim());

              return (
                <tr key={uniqueId()} className={semibold ? semiboldRow : normalRow}>
                  {
                    row.length === 0 && (
                      <td colSpan={heading.length} className="p-2"></td>
                    )
                  }
                  {
                    row.length > 0 && row.map((cell) => (
                      <td key={uniqueId()} className="px-6 py-4">
                        <FormatCell value={cell} />
                      </td>
                    ))
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </>
  )
}

export default BalanceSheet;
