import React from 'react';
import { useHref, useLinkClickHandler } from 'react-router-dom';

interface SidenavGroupItemProps {
  to: string;
}

function SidenavGroupItem({ children, to }: React.PropsWithChildren & SidenavGroupItemProps) {
  return (
    <li>
      <a href={useHref(to)} onClick={useLinkClickHandler(to)} className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
        <span>{children}</span>
      </a>
    </li>
  );
}

export default SidenavGroupItem;
