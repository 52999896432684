import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
import { ListGroupItem } from './ListGroupItem';
const ListGroupComponent = ({ children, className, theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.listGroup, customTheme);
    return (_jsx("ul", { className: classNames(theme.root.base, className), ...props, children: children }));
};
ListGroupComponent.displayName = 'ListGroup';
ListGroupItem.displayName = 'ListGroup.Item';
export const ListGroup = Object.assign(ListGroupComponent, { Item: ListGroupItem });
