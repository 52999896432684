import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import ExecutiveSummary from './pages/ExecutiveSummary';
import FinanceBalanceSheet from './pages/FinanceBalanceSheet';
import OperationsHR from './pages/OperationsHR';
import Sales from './pages/Sales';
import FinanceRevenue from './pages/FinanceRevenue';
import { AuthenticationProvider } from './contexts/Authentication';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="executive-summary" element={<ExecutiveSummary />} />
      <Route path="finance-revenue" element={<FinanceRevenue />} />
      <Route path="finance-balance-sheet" element={<FinanceBalanceSheet />} />
      <Route path="operations-hr" element={<OperationsHR />} />
      <Route path="sales" element={<Sales />} />
      <Route path="/" element={<Navigate to="finance-revenue" />} />
      <Route path="*" element={<Navigate to="finance-revenue" />} />
    </Route>
  ),
);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="jaxma.au.auth0.com"
      clientId="7x40YTBSiUmjnzdOjsVZGr9DPcRCxTHn"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: 'https://enablo-dashboard.jaxma.au',
      }}
    >
      <AuthenticationProvider>
        <RouterProvider router={router} />
      </AuthenticationProvider>
    </Auth0Provider>
  </React.StrictMode>
);

