import React from 'react';
import classNames from 'classnames';
import { useHref } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import SideNav from './SideNav';
import { useAuthentication } from '../../contexts/Authentication';

interface PageProps {
  children: React.ReactNode;
}

function Page({ children }: PageProps) {
  const { logout } = useAuth0();
  const { user } = useAuthentication();

  const [open, setOpen] = React.useState(false);

  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node) && open) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref.current]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleLogout = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <>
      <SideNav />
      <div className="sm:ml-64">
        <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
          <div className="flex justify-end relative">
            <button className="flex py-2 px-4 items-center" type="button" onClick={handleClick}>
              <img className="w-8 h-8 rounded" src={user?.picture} alt={user?.name} />
            </button>
            <div
              ref={ref}
              className={classNames({ hidden: !open }, 'absolute z-50 my-4 mt-10 mx-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600')}
            >
              <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                <div>{user?.name}</div>
                <div className="font-medium truncate">{user?.email}</div>
              </div>
              <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownUserAvatarButton">
                <li>
                  <a
                    href={useHref('/')}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={handleLogout}
                  >
                      Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="md:container md:mx-auto pt-14">
          {children}
        </div>
      </div>
    </>
  );
}

export default Page;
