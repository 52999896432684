import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { HiStar } from 'react-icons/hi';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
import { useRatingContext } from './RatingContext';
export const RatingStar = ({ className, filled = true, starIcon: Icon = HiStar, theme: customTheme = {}, ...props }) => {
    const { size = 'sm' } = useRatingContext();
    const theme = mergeDeep(useTheme().theme.rating.star, customTheme);
    return (_jsx(Icon, { "data-testid": "flowbite-rating-star", className: classNames(theme.sizes[size], theme[filled ? 'filled' : 'empty'], className), ...props }));
};
