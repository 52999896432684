import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
import { Spinner } from '../Spinner';
import ButtonGroup from './ButtonGroup';
const ButtonComponent = forwardRef(({ children, className, color = 'info', disabled = false, fullSized, isProcessing = false, processingLabel = 'Loading...', processingSpinner: SpinnerComponent = _jsx(Spinner, {}), gradientDuoTone, gradientMonochrome, href, label, outline = false, pill = false, positionInGroup = 'none', size = 'md', theme: customTheme = {}, ...props }, ref) => {
    const { buttonGroup: groupTheme, button: theme } = mergeDeep(useTheme().theme, customTheme);
    const isLink = typeof href !== 'undefined';
    const Component = isLink ? 'a' : 'button';
    const theirProps = props;
    return (_jsx(Component, { disabled: disabled, href: href, type: isLink ? undefined : 'button', ref: ref, className: classNames(disabled && theme.disabled, !gradientDuoTone && !gradientMonochrome && theme.color[color], gradientDuoTone && !gradientMonochrome && theme.gradientDuoTone[gradientDuoTone], !gradientDuoTone && gradientMonochrome && theme.gradient[gradientMonochrome], groupTheme.position[positionInGroup], outline && (theme.outline.color[color] ?? theme.outline.color.default), theme.base, theme.pill[pill ? 'on' : 'off'], fullSized && theme.fullSized, className), ...theirProps, children: _jsx("span", { className: classNames(theme.inner.base, theme.inner.position[positionInGroup], theme.outline[outline ? 'on' : 'off'], theme.outline.pill[outline && pill ? 'on' : 'off'], theme.size[size], outline && !theme.outline.color[color] && theme.inner.outline, isProcessing && theme.isProcessing), children: _jsxs(_Fragment, { children: [isProcessing && _jsx("span", { className: theme.spinnerSlot, children: SpinnerComponent }), typeof children !== 'undefined' ? (children) : (_jsx("span", { "data-testid": "flowbite-button-label", className: theme.label, children: isProcessing ? processingLabel : label }))] }) }) }));
});
ButtonComponent.displayName = 'Button';
export const Button = Object.assign(ButtonComponent, {
    Group: ButtonGroup,
});
