import React from 'react';
import { cloneDeep } from 'lodash';
import Chart from 'react-apexcharts';

import Title from './Title';
import { SpreadsheetProxy, Values } from './types';
import { excelDate, trendLine } from './utils';
import { useAuthentication } from '../../contexts/Authentication';

const range = 'HR - Misc!B6:Z7';

const charts = {
  options: {
    chart: { id: 'unplanned-leave' },
    xaxis: {
      categories: [] as string[],
    },
    yaxis: {
      labels: {
        formatter: (value: number) => String(Math.round(value)),
      }
    },
    stroke: {
      width: [5, 3],
      dashArray: [0, 8],
    }
  },
  total: [{
    name: 'Hours',
    data: [] as number[],
  }]
};

function UnplannedLeave() {
  const { api } = useAuthentication();
  const [values, setValues] = React.useState<Values>([]);

  const load = async () => {
    const { data } = await api.get<SpreadsheetProxy>('/proxy/google-sheets', { params: { range } });
    setValues(data.data.values);
  };

  const chart = React.useMemo(() => {
    if (values.length === 0) return charts;

    const c = cloneDeep(charts);

    c.options.xaxis.categories = values[0].map((v) => excelDate(v as number).toFormat('LLL yyyy'));
    c.total[0].data = values[1] as number[];

    c.total.push({
      name: 'Trend',
      data: trendLine(c.total[0].data),
    });

    return c;
  }, [values]);

  React.useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <Title subtitle="Total number of hours">Unplanned leave</Title>
      <div className="px-4 pb-4">
        <Chart
          options={chart.options}
          series={chart.total}
          type="line"
          height={300}
        />
      </div>
    </div>
  );
}

export default UnplannedLeave;
