import React from 'react';

import { Card, Grid } from '../../components/Flowbite';
import { EmployeeCountOverTime, HRStats, Tenure, UnplannedLeave } from '../../components/Data';

function OperationsHR() {
  return (
    <>
      <div className="mb-4">
        <HRStats />
      </div>
      <div className="mb-4">
        <Card>
          <EmployeeCountOverTime />
        </Card>
      </div>
      <Grid cols={2} gap={4}>
        <Card>
          <Tenure />
        </Card>
        <Card>
          <UnplannedLeave />
        </Card>
      </Grid>
    </>
  );
}

export default OperationsHR;
