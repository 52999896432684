import React from 'react';
import { } from 'flowbite-react';

import { Card, Grid } from '../../components/Flowbite';
import { EarningsReport } from '../../components/Data';

function ExecutiveSummary() {
  return (
    <>
      <Grid cols={6} gap={4}>
        <Card className="col-span-4">
          <EarningsReport />
        </Card>
        <Card className="col-span-2" />
        <Card />
        <Card />
        <Card />
      </Grid>
    </>
  );
}

export default ExecutiveSummary;
