import React from 'react';
import { useHref, useLinkClickHandler } from 'react-router-dom';

interface SidenavItemProps {
  to: string;
  icon?: (props: { className: string }) => JSX.Element;
}

function SidenavItem({ children, to, icon: Icon }: React.PropsWithChildren & SidenavItemProps) {
  return (
    <li>
      <a href={useHref(to)} onClick={useLinkClickHandler(to)} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
        {Icon && <Icon className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />}
        <span className="ml-3">{children}</span>
      </a>
    </li>
  );
}

export default SidenavItem;
