import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
import { useModalContext } from './ModalContext';
export const ModalFooter = ({ children, className, theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.modal.footer, customTheme);
    const { popup } = useModalContext();
    return (_jsx("div", { className: classNames(theme.base, {
            [theme.popup]: !popup,
        }, className), ...props, children: children }));
};
