import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite';
import { useTimelineContext } from './TimelineContext';
export const TimelineItem = ({ children, className, theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.timeline.item, customTheme);
    const { horizontal } = useTimelineContext();
    return (_jsx("li", { "data-testid": "timeline-item", className: classNames(horizontal && theme.root.horizontal, !horizontal && theme.root.vertical, className), ...props, children: children }));
};
