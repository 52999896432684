import React from 'react';
import classNames from 'classnames';

interface GridProps {
  cols: number;
  gap: number;
  children: React.ReactNode;
}

const colClasses: Record<number, string> = {
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
  9: 'grid-cols-9',
  10: 'grid-cols-10',
  11: 'grid-cols-11',
  12: 'grid-cols-12',
};

const gapClasses: Record<number, string> = {
  1: 'gap-1',
  2: 'gap-2',
  3: 'gap-3',
  4: 'gap-4',
};

function Grid({ cols, gap, children }: GridProps) {
  const className = classNames('grid', colClasses[cols], gapClasses[gap]);

  return (
    <div className={className}>
      {children}
    </div>
  );
}

export default Grid;
