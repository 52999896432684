import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { GoThreeBars } from 'react-icons/go';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
import { useNavbarContext } from './NavbarContext';
export const NavbarToggle = ({ barIcon: BarIcon = GoThreeBars, className, theme: customTheme = {}, ...props }) => {
    const { isOpen, setIsOpen } = useNavbarContext();
    const theme = mergeDeep(useTheme().theme.navbar.toggle, customTheme);
    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    return (_jsxs("button", { "data-testid": "flowbite-navbar-toggle", onClick: handleClick, className: classNames(theme.base, className), ...props, children: [_jsx("span", { className: "sr-only", children: "Open main menu" }), _jsx(BarIcon, { "aria-hidden": true, className: theme.icon })] }));
};
